@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.term-agreement-date {
  color: var(--v-primary-base);
  font-size: var(--font-size-norm);
  margin-bottom: 0.25rem;
}
.term-agreement-version {
  color: var(--v-primary-base);
  font-size: var(--font-size-sm);
}

::v-deep h2 {
  margin: 2.5rem 0 1rem;
  text-transform: uppercase;
}
::v-deep h3 {
  margin: 1.25rem 0 0.25rem;
}

::v-deep dt {
  font-weight: 500;
}

::v-deep p,
::v-deep dd {
  font-size: var(--font-size-sm);
  line-height: 1.333333;
  margin-bottom: 1rem;
}
